import Container from 'react-bootstrap/Container';
import {Link} from 'react-router-dom';
import React, { useState, useEffect } from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import detailImage from '../../assets/images/detail-img.webp';
import retreatVideo from '../../assets/images/retreat-video.webp';
import homeLocation from '../../assets/images/home-location.webp';
import testimonial1 from '../../assets/images/testimonial1.webp';
import './details.css';
function Details(props) {
    const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 110)
    })
  }, [])
    return (
    <div className="wrapper-details">
        <section className="section detail-section">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="back-wrap">
                            <Button><span class="fa fa-chevron-left" aria-hidden="true"></span> Back</Button>
                            <h3 className="detail-page-title"><a href="">14 DAYS MASTER PLANT DIET</a></h3>
                        </div>
                        <ul className="detail-meta">
                            <li className="each-meta">
                                <span class="fa fa-star" aria-hidden="true"></span><span>4.5 · 89 Reviews</span>
                            </li>
                            <li className="each-meta">
                                <span class="fa fa-calendar-o" aria-hidden="true"></span><span>October 21 - 31, 2023</span>
                            </li>
                            <li className="each-meta">
                                <span class="fa fa-map-marker" aria-hidden="true"></span><span>Amazon, Peru</span>
                            </li>
                        </ul>
                    </Col>
                    <Col md={12} lg={8}>
                        <div className="detail-page-info">
                            <div className="detail-image-wrap">
                                <a href="">
                                    <img width="840px" height="420px" src={detailImage} alt="10-Day Embodying True Nature Intensive" />
                                </a>
                            </div>
                            <ul className="detail-flags">
                                <li className="each-flag"><a href="#">Pre Screening</a><span>24-30 June, 2023</span></li>
                                <li className="each-flag"><a href="#">Pre integration</a><span>30-02 July, 2023</span></li>
                                <li className="each-flag"><a href="#">PROGRAM</a><span>10-16 July, 2023</span></li>
                                <li className="each-flag"><a href="#">POST integration</a><span>20-22 July, 2023</span></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} lg={4}>
                       <div className={scroll ? "scrolled booking-section" : "booking-section"}>
                        <div className="booking-step">
                            <h3 className="booking-title">Available Dates</h3>
                            <select class="form-select" aria-label="Default select example">
                                <option selected>October 21 - 31, 2023</option>
                                <option value="1">October 21 - 31, 2023</option>
                                <option value="2">October 21 - 31, 2023</option>
                                <option value="3">October 21 - 31, 2023</option>
                            </select>
                            {/* <h3 className="booking-title mt-3 mb-0">Select a Room</h3> */}
                        </div>
                        <div className="booking-step scroll-smooth room-step">
                            <h3 className="booking-title mt-3 mt-lg-4">Category</h3>
                            <p className="mb-0">Ayahuasca & Medicine Retreats</p>
                            <h3 className="booking-title mt-3 mt-lg-4">Sub Category</h3>
                            <p className="mb-0">-</p>
                            {/* <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room1" name="radio" type="radio" checked />
                                <label  class="custom-radio rooms-radio-btn" for="room1">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Basic Room · Without Washroom</p>
                                        <h6>$1,780.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room2" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room2">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Luxury Room · With Washroom</p>
                                        <h6>$2,180.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div>
                            <div  class="cuctom-checkbox room-select-radio">
                                <input  id="room3" name="radio" type="radio" />
                                <label  class="custom-radio rooms-radio-btn" for="room3">
                                    <span className="checkbox-icon"></span>
                                    <div  class="custom-radio-title">
                                        <p>Premium ROOM · Without bathroom</p>
                                        <h6>$2,580.00</h6>
                                    </div>
                                </label>
                            </div> */}
                        </div>
                        <div className="booking-footer">
                            <div className="footer-content text-center">
                                <div className="booking-btn-wrap text-center">
                                    <Link className='btn-primary btn' to="booking-plan">Register Interest</Link>
                                    </div>
                                <a className="refund-link">Refund & Cancellation</a>
                            </div>
                        </div>
                       </div>
                    </Col>
                    <Col md={12} lg={8}>
                        <div className="detail-page-info">
                            <div className="detail-sub-section">
                                <h4 className="detail-sub-title retreat-title">About this Retreat</h4>
                                <p className="detail-sub-text">The 10-Day Intensive Embody True Nature retreat weaves ancestral living wisdom traditions supported by Jungian transpersonal psychology to provide a foundation to both discover and develop the innermost purpose of one’s life.</p>
                                <div className="retreat-video">
                                    <img width="465" height="263" src={retreatVideo} alt="About this Retreat" />
                                </div>
                            </div>
                            <div className='hr' />
                            <div className="detail-sub-section">
                                <h4 className="detail-sub-title">Overview</h4>
                                <p className="detail-sub-text">Join us in Peru’s Sacred Valley near the city of Cusco - an ancient capital of the Inca empire for a hero’s journey of self discovery into the infinite human potential. Together we will transcend the layers of our individual and collective conditioning in order to know...</p>
                                <a className="read-more" href="#">Read more</a>
                            </div>
                            <div className='hr' />
                            <div className="detail-sub-section">
                                <h4 className="detail-sub-title">Where you’ll be</h4>
                                <p className="detail-sub-text">Paititi Institute, Peru</p>
                                <div className="home-location">
                                    <img width="840" height="360" src={homeLocation} alt="Where you’ll be" />
                                </div>
                            </div>
                            <div className='hr' />
                            <div className="detail-sub-section">
                                <h4 className="detail-sub-title">Meals</h4>
                                <ul className="meal-listing">
                                    <li>

                                    </li>
                                </ul>
                            </div>
                            <div className='hr' />
                            <div className="detail-sub-section">
                                <h4 className="detail-sub-title">Customer Testimonials · 240</h4>
                                <div className="detail-testimonial">
                                    <div className="user-main-info d-flex align-items-center">
                                        <div className="user-avtar">
                                            <img width="48" height="48" src={testimonial1} alt="Muhammad Asif" />
                                        </div>
                                        <div className="user-info-detail">
                                            <h5 className="username-detail">Muhammad Asif</h5>
                                            <p className="useremail-detail"><a href="mailto=asif.muhammad@cmail.co" ></a>asif.muhammad@cmail.co</p>
                                        </div>
                                    </div>
                                    <div className="user-review">
                                        <p>We stayed in a glamping tent in the forest. It was amazing with comfortable beds, soft bedding and even hot water bottles. The organic food was plentiful and delicious. How do I get some of those recipes?Robin and Darcy have created a safe space where I felt truely supported to do deep work. 🙏 ❤️ I will be back!</p>
                                    </div>
                                </div>
                                <div className="detail-testimonial">
                                    <div className="user-main-info d-flex align-items-center">
                                        <div className="user-avtar">
                                            <img width="48" height="48" src={testimonial1} alt="Muhammad Asif" />
                                        </div>
                                        <div className="user-info-detail">
                                            <h5 className="username-detail">Muhammad Asif</h5>
                                            <p className="useremail-detail"><a href="mailto=asif.muhammad@cmail.co" ></a>asif.muhammad@cmail.co</p>
                                        </div>
                                    </div>
                                    <div className="user-review">
                                        <p>We stayed in a glamping tent in the forest. It was amazing with comfortable beds, soft bedding and even hot water bottles. The organic food was plentiful and delicious. How do I get some of those recipes?Robin and Darcy have created a safe space where I felt truely supported to do deep work. 🙏 ❤️ I will be back!</p>
                                    </div>
                                </div>
                                <div className="detail-testimonial">
                                    <div className="user-main-info d-flex align-items-center">
                                        <div className="user-avtar">
                                            <img width="48" height="48" src={testimonial1} alt="Muhammad Asif" />
                                        </div>
                                        <div className="user-info-detail">
                                            <h5 className="username-detail">Muhammad Asif</h5>
                                            <p className="useremail-detail"><a href="mailto=asif.muhammad@cmail.co" ></a>asif.muhammad@cmail.co</p>
                                        </div>
                                    </div>
                                    <div className="user-review">
                                        <p>We stayed in a glamping tent in the forest. It was amazing with comfortable beds, soft bedding and even hot water bottles. The organic food was plentiful and delicious. How do I get some of those recipes?Robin and Darcy have created a safe space where I felt truely supported to do deep work. 🙏 ❤️ I will be back!</p>
                                    </div>
                                </div>
                                <div className='hr' />
                                <div className="terms-section mb-lg-5 mb-3">
                                    <h4 className="detail-sub-title">Terms & Conditions</h4>
                                    <p className="terms-title">These rules and policies are subject to change at any time.</p>
                                    <p>1. All guests must pass through our medical and psychological intake process in order to be accepted on retreat. Soltara reserves the right not to confirm any guest who does not pass our intake process.</p>
                                    <p>2. To book your reservation and reserve your spot, a 50-75% deposit is due upon registration, depending on the retreat type, payable electronically. The remainder is due in cash on arrival (in crisp USD – we appreciate your understanding as on-site operations are run entirely in cash).</p>
                                    <a className="more-terms" href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    );
}
export default Details;
