import * as Yup from "yup";


export const BookingPlanSchema = Yup.object({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required("Please enter your email"),
    phoneNumber: Yup.number().typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(10).required('A phone number is required'),
    birthDate: Yup.string().min(2).max(20).required('A date of birth is required'),
    dietaryPrefrence: Yup.string().min(2).max(20).required("Please enter your dietary prefrence"),
    emergencyContactName: Yup.string().min(2).max(20).required("Please enter your emergency contact name"),
    emergencyContactPhone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(10)
        .required('A phone number is required'),
    donation: Yup.string().min(2).required("Please enter your phone number"),
})