import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import philosophyMain from '../../assets/images/philosophy-main.webp';
import philosophyImg from '../../assets/images/philosophy-img.webp';
import medicineImg from '../../assets/images/medicine-img.webp';
import medicine1 from '../../assets/images/medicine1.webp';
import medicine2 from '../../assets/images/medicine2.webp';
import medicine3 from '../../assets/images/medicine3.webp';
import Center1 from '../../assets/images/center1.webp';
import Center2 from '../../assets/images/center2.webp';
import Center3 from '../../assets/images/center3.webp';
import bannerCard1 from '../../assets/images/banner-card1.webp';
import bannerCard2 from '../../assets/images/banner-card2.webp';
import bannerCard3 from '../../assets/images/banner-card3.webp';
import diatesImg from '../../assets/images/diates-img.webp';
import mediatab1 from '../../assets/images/mediatab1.webp';
import testimonial1 from '../../assets/images/testimonial1.webp';
import teamIcon from '../../assets/images/team-icon.webp';
import teamIcon2 from '../../assets/images/team-icon2.webp';
import teamIcon3 from '../../assets/images/team-icon3.webp';
import product1 from '../../assets/images/product1.webp';
import article1 from '../../assets/images/article1.webp';
import article2 from '../../assets/images/article2.webp';
import flowerBg from '../../assets/images/flower-bg.webp';
import quoteIcon from '../../assets/images/quote.svg';
import './home.css';
const optionsBanner = {
    margin: 30,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    items: 1,
};
const optionsProducts = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    navText: ["<span class='fa fa-angle-left' aria-hidden='true'></span>","<span class='fa fa-angle-right' aria-hidden='true'></span>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};
const optionsTestimonial = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 3,

        },
        1200: {
            items: 4,

        }
    },
};
const optionsTeam = {
    margin: 20,
    nav: false,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            stagePadding: 40,
            center: true,
        },
        768: {
            items: 2,
            center: false,
        },
        1000: {
            items: 3,

        }
    },
};
const optionsProductCard = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            stagePadding: 40,
            center: true,
            loop:true,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 3,

        },
        1200: {
            items: 4,

        }
    },
};
const optionsCenter = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            stagePadding: 100,
            center: true,
            loop:true,
        },
        576: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};
const optionsMediaMobile = {
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    items: 1,
    navText: ["<span class='fa fa-angle-left' aria-hidden='true'></span>","<span class='fa fa-angle-right' aria-hidden='true'></span>"],
    smartSpeed: 1000,
};
function Home() {
    return (
    <div className="wrapper-home">
        <section className="section banner-section">
            <div className="banner-title-section">
                <Container>
                    <Row>
                        <Col md={7}>
                            <h1 className="banner-title">heart OF THE JUNGLE</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="banner-card-section-wrap">
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}></Col>
                        <Col className="d-none d-md-block" sm={12} md={6}>
                            <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                <div className="banner-card-image">
                                    <img width="150px" height="130px" src={bannerCard1} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="banner-card-content">
                                    <div className="banner-content-wrap">
                                        <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                        <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                        <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                        <div className="book-card d-flex justify-content-between">
                                            <p className="card-price">From <strong>$1,230</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                <div className="banner-card-image">
                                    <img width="150px" height="130px" src={bannerCard2} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="banner-card-content">
                                    <div className="banner-content-wrap">
                                        <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                        <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Sep 10, 2023 to Sep 24, 2023</p>
                                        <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                        <div className="book-card d-flex justify-content-between">
                                            <p className="card-price">From <strong>$1,230</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                <div className="banner-card-image">
                                    <img width="150px" height="130px" src={bannerCard3} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="banner-card-content">
                                    <div className="banner-content-wrap">
                                        <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                        <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Nov 12, 2023 to July 26, 2023</p>
                                        <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                        <div className="book-card d-flex justify-content-between">
                                            <p className="card-price">From <strong>$1,230</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="d-block d-md-none" sm={12}>
                            <div className="banner-slides">
                                <OwlCarousel className='owl-theme banner-carousel' {...optionsBanner}>
                                    <div className="item">
                                        <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                            <div className="banner-card-image">
                                                <img width="150px" height="130px" src={bannerCard1} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="banner-card-content">
                                                <div className="banner-content-wrap">
                                                    <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                    <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <div className="book-card d-flex justify-content-between">
                                                        <p className="card-price">From <strong>$1,230</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                            <div className="banner-card-image">
                                                <img width="150px" height="130px" src={bannerCard1} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="banner-card-content">
                                                <div className="banner-content-wrap">
                                                    <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Sep 10, 2023 to Sep 24, 2023</p>
                                                    <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <div className="book-card d-flex justify-content-between">
                                                        <p className="card-price">From <strong>$1,230</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="programs/details" className="banner-card-section align-items-center d-flex">
                                            <div className="banner-card-image">
                                                <img width="150px" height="130px" src={bannerCard1} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="banner-card-content">
                                                <div className="banner-content-wrap">
                                                    <h4 className="banner-card-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="card-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Nov 12, 2023 to July 26, 2023</p>
                                                    <p className="card-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <div className="book-card d-flex justify-content-between">
                                                        <p className="card-price">From <strong>$1,230</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
        <article className="article philosophy-article">
            {/* Philosophy Section */}
            <section className="section philosophy-section">
                <Container>
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <div className='philosophy-content'>
                                <h3 className="section-title">Our Philosophy</h3>
                                <p>Natural supplements never mean safe supplements, it is a misconception when it comes to substance abuse. Ayahuaca is a psychoactive substance which is comprised of a few different things including banisteriopsis caapi vine and chacruna Psychotria viridis among other things. This plant grows in the Amazon regions, the reason it is categorized as psychoactive is because it creates altered states of consciousness for the user.</p>
                                <p>When someone is under the influence of Ayahuaca, the results can vary from one person to the next, because every person reacts differently to different substances. For example, some people can use ayahuasca and just find that it’s a little stimulating, while other people report having extreme visions.</p>
                            </div>
                        </Col>
                        <Col className="justify-content-md-end justify-content-center d-flex" sm={12} md={6}>
                            <div className='philosophy-image'>
                                <img width="365px" height="460px" className="main-image" src={philosophyMain} alt='Philosophy image' />
                                <img width="248px" height="200" className="phil-image" src={philosophyImg} alt='Philosophy icon' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section team-section">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='team-content'>
                                <h3 className="title-small">Our Team</h3>
                                <p>Meet our experienced team members.</p>
                            </div>
                        </Col>
                        <OwlCarousel className='owl-theme team-carousel' {...optionsTeam}>
                            <div className="item">
                                <Link to="team/team-detail" className="team-card">
                                    <div className="team-avtar">
                                        <img width="95px" height="95px" src={teamIcon} alt='Creator Andrew' />
                                    </div>
                                    <div className="team-wrap">
                                        <p className="team-role">Your Maestro</p>
                                        <h4 className="team-member">Papa Gilberto Mahua</h4>
                                        <p className="team-info">"Papa" Gilberto, as his students call him, is one of the last curanderos (healers) that received an extended training in traditional curanderismo. He started dieting at the age of twelve years old, along with four of his brothers..<Link to="team/team-detail">Read more</Link></p>
                                    </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="team/team-detail" className="team-card">
                                    <div className="team-avtar">
                                        <img width="95px" height="95px" src={teamIcon2} alt='Creator Andrew' />
                                    </div>
                                    <div className="team-wrap">
                                        <p className="team-role">Your Facilitator</p>
                                        <h4 className="team-member">Isabelle Garceau</h4>
                                        <p className="team-info">Isabelle Garceau comes from Quebec, Canada. She inherited Native American roots from her grandfather and has always been fascinated by indigenous culture, and knowledge. She has traveled all around the world...<Link to="team/team-detail">Read more</Link></p>
                                    </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="team/team-detail" className="team-card">
                                    <div className="team-avtar">
                                        <img width="95px" height="95px" src={teamIcon3} alt='Creator Andrew' />
                                    </div>
                                    <div className="team-wrap">
                                        <p className="team-role">Your Facilitator</p>
                                        <h4 className="team-member">Alexa Fletcher</h4>
                                        <p className="team-info">These peoples are some of the main guardians of this medicinal healing art. Most of their works of art, fabrics, beadings, potteries and even their house adornments are decorated with the patterns of ayahuasca visions...<Link to="team/team-detail">Read more</Link></p>
                                    </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="team/team-detail" className="team-card">
                                    <div className="team-avtar">
                                        <img width="95px" height="95px" src={teamIcon} alt='Creator Andrew' />
                                    </div>
                                    <div className="team-wrap">
                                        <p className="team-role">Your Maestro</p>
                                        <h4 className="team-member">Papa Gilberto Mahua</h4>
                                        <p className="team-info">"Papa" Gilberto, as his students call him, is one of the last curanderos (healers) that received an extended training in traditional curanderismo. He started dieting at the age of twelve years old, along with four of his brothers..<Link to="team/team-detail">Read more</Link></p>
                                    </div>
                                </Link>
                            </div>
                        </OwlCarousel>
                        <Col sm={12}>
                            <div className="team-all text-center">
                                <Link to="team" className="view-all-btn">View All Members</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </article>
        <section className="section medicine-section">
            <Container fluid className="px-0">
                <Row className="mx-0">
                    <Col className="px-0" sm={12} md={5}>
                        <div className='medicine-image'>
                            <img width="603px" height="366px" src={medicineImg} alt="Medicine Image" />
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-start" sm={12} md={7}>
                        <div className='medicine-content'>
                            <h3 className="section-title">Ayahuasca & Medicine</h3>
                            <p>Ayahuasca comes from the Quechua language and means “wine of the soul”. It allows us to connect with our essence - our soul, and also our subconscious. The Ayahuasca brew is a combination of two different plants....<Link className="read-more" to="madre-ayahuasca">Read more</Link></p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="medicine-row">
                    <OwlCarousel className='owl-theme team-carousel' {...optionsProductCard}>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIETe</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                        <Link to="programs/details" className="medicine-card">
                            <div className="medicine-banner">
                                <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                            </div>
                            <div className="medicine-wrap">
                                <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                <p className="medicine-price">From <strong>$1,230</strong></p>
                            </div>
                        </Link>
                        </div>
                    </OwlCarousel>
                    <Col sm={12}>
                        <div className="team-all text-center">
                            <Link className="view-all-btn" to="programs">View Programs</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section medicine-section">
            <Container fluid className="px-0">
                <Row className="mx-0">
                    <Col className="px-0" sm={12} md={5}>
                        <div className='medicine-image'>
                            <img width="603px" height="366px" src={diatesImg} alt="Medicine Image" />
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-start" sm={12} md={7}>
                        <div className='medicine-content'>
                            <h3 className="section-title">Master Plants Dietas</h3>
                            <p>The diet or Sama is an important aspect of Shipibo medicinal knowledge. It is an ancestral way of healing the body, mind, emotions, spirit, and soul at a very deep level. It mainly relies on the power and properties of different plants.... <Link className="read-more" to="master-plants-dietas">Read more</Link></p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="medicine-row">
                    <OwlCarousel className='owl-theme team-carousel' {...optionsProductCard}>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine2} alt='10-Day Embodying True Nature Intensive' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="programs/details" className="medicine-card">
                                <div className="medicine-banner">
                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                </div>
                                <div className="medicine-wrap">
                                    <h4 className="medicine-name">14 DAYS MASTER PLANT DIET</h4>
                                    <p className="medicine-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                    <p className="medicine-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                    <p className="medicine-price">From <strong>$1,230</strong></p>
                                </div>
                            </Link>
                        </div>
                    </OwlCarousel>
                    <Col sm={12}>
                        <div className="team-all text-center">
                            <Link to="programs" className="view-all-btn" href="">View All Retreats</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section id="centers" className="section center-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="center-title-wrap">
                            <h3 className="section-title">View Centre</h3>
                            <p>Our Program Centres and Locations provide safe & supportive containers to dive deeply into your healing.</p>
                        </div>
                    </Col>
                    <OwlCarousel className='owl-theme center-carousel' {...optionsCenter}>
                        <div className="item">
                            <Link to="centers" className="center-card">
                                <div className="center-banner">
                                    <div className="center-flag"><Link to="centers">View Programs</Link></div>
                                    <img width="413px" height="384px" src={Center1} alt='10-Day Embodying True Nature Intensive' />
                                </div>
                                <div className="center-wrap">
                                    <h4 className="center-name">Amazon, Peru</h4>
                                    <p className="center-count">24 Retreats · 1,231 Guests</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="centers" className="center-card">
                                <div className="center-banner">
                                    <div className="center-flag"><Link to="centers">View Programs</Link></div>
                                    <img width="413px" height="384px" src={Center2} alt='10-Day Embodying True Nature Intensive' />
                                </div>
                                <div className="center-wrap">
                                    <h4 className="center-name">Amazon, Peru</h4>
                                    <p className="center-count">24 Retreats · 1,231 Guests</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="centers" className="center-card">
                                <div className="center-banner">
                                    <div className="center-flag"><Link to="centers">View Programs</Link></div>
                                    <img width="413px" height="384px" src={Center3} alt='10-Day Embodying True Nature Intensive' />
                                </div>
                                <div className="center-wrap">
                                    <h4 className="center-name">Amazon, Peru</h4>
                                    <p className="center-count">24 Retreats · 1,231 Guests</p>
                                </div>
                            </Link>
                        </div>
                    </OwlCarousel>
                </Row>
            </Container>
        </section>
        <section className="section media-tabs media-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="media-top-section">
                            <h3 className="title-small">Media</h3>
                            <p>Meet our experienced team members.</p>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="pill" href="#audio">Audio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="pill" href="#video">Video</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div id="audio" className="tab-pane active">
                                <div className="media-content-tab">
                                    <Row className="d-none d-md-flex">
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="d-block d-md-none">
                                        <OwlCarousel className='owl-theme banner-carousel' {...optionsMediaMobile}>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                            <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </OwlCarousel>
                                    </Row>
                                </div>
                            </div>
                            <div id="video" className="tab-pane fade">
                                <div className="media-content-tab">
                                    <Row className="d-none d-md-flex">
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <div className="media-card">
                                                <div className="media-avtar">
                                                    <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                </div>
                                                <div className="media-content">
                                                    <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                    <div className="media-view-time">
                                                        <span className="media-time">1:37:23</span>
                                                        <span className="media-view">12M Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="d-block d-md-none">
                                        <OwlCarousel className='owl-theme banner-carousel' {...optionsMediaMobile}>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                            <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="item">
                                                <Row className="mx-0">
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="media-card">
                                                            <div className="media-avtar">
                                                                <img width="96px" height="85px" src={mediatab1} alt="Peru Amazon Ayahuasca Shamanic Songs" />
                                                            </div>
                                                            <div className="media-content">
                                                                <h4 className="media-title-text">Peru Amazon Ayahuasca Shamanic Songs</h4>
                                                                <div className="media-view-time">
                                                                    <span className="media-time">1:37:23</span>
                                                                    <span className="media-view">12M Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </OwlCarousel>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section products-section">
            <Container fluid>
                <Row>
                    <Col className="px-0" sm={12} md={6}>
                        <div className="product-info-title">
                            <img width="376px" height="250px" src={flowerBg} alt="Browse Traditional Shipibo Products" />
                            <h3 className="product-title-main">Browse Traditional Shipibo Products</h3>
                        </div>
                    </Col>
                    <Col className="px-0" sm={12} md={6}>
                        <div className="product-info-text">
                            <p className="product-text">Natural supplements never mean safe supplements, it is a misconception when it comes to substance abuse. Ayahuaca is a psychoactive substance which is comprised of a few different things.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col sm={12}>
                        <OwlCarousel className='owl-theme product-carousel' {...optionsProducts}
                            >
                            <div className='item'>
                                <div className="product-card">
                                    <div className="product-image">
                                        <img width="280px" height="280px" src={product1} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                    </div>
                                    <div className="product-overview">
                                        <h4 className="product-title">Harem Pants w/Pockets Shipibo Icaro Pattern</h4>
                                        <p className="product-price"><strong>$1230</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="product-card">
                                    <div className="product-image">
                                        <img width="280px" height="280px" src={product1} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                    </div>
                                    <div className="product-overview">
                                        <h4 className="product-title">Harem Pants w/Pockets Shipibo Icaro Pattern</h4>
                                        <p className="product-price"><strong>$1230</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="product-card">
                                    <div className="product-image">
                                        <img width="280px" height="280px" src={product1} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                    </div>
                                    <div className="product-overview">
                                        <h4 className="product-title">Harem Pants w/Pockets Shipibo Icaro Pattern</h4>
                                        <p className="product-price"><strong>$1230</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="product-card">
                                    <div className="product-image">
                                        <img width="280px" height="280px" src={product1} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                    </div>
                                    <div className="product-overview">
                                        <h4 className="product-title">Harem Pants w/Pockets Shipibo Icaro Pattern</h4>
                                        <p className="product-price"><strong>$1230</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="product-card">
                                    <div className="product-image">
                                        <img width="280px" height="280px" src={product1} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                    </div>
                                    <div className="product-overview">
                                        <h4 className="product-title">Harem Pants w/Pockets Shipibo Icaro Pattern</h4>
                                        <p className="product-price"><strong>$1230</strong></p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </Col>
                    <Col sm={12}>
                        <div className="team-all text-center">
                            <a className="view-all-btn" href="">View All Products</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section id="articles" className="section article-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="article-top-section">
                            <h3 className="title-small">Articles</h3>
                            <p>Meet our experienced team members.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="article-listing-row">
                    <Col lg={6} sm={12}>
                        <div className="article-card">
                            <div className="article-image">
                                <img width="635" height="418" src={article1} alt="Sacha Wasi Retreats - 3 Day / 2 Nights Weekend: Ayahuasca" />
                            </div>
                            <div className="article-main-content">
                                <div className='article-date'><span className="fa fa-calendar-o" aria-hidden="true"></span> October 25, 2023</div>
                                <h4 className="article-cat-title">Sacha Wasi Retreats - 3 Day / 2 Nights Weekend: Ayahuasca</h4>
                                <p className="article-text">Natural supplements never mean safe kind of supplements, it is a misconception when it now to substance abuse.... Read more</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} sm={12}>
                        <div className="article-card">
                            <div className="article-image">
                                <img width="305" height="219" src={article2} alt="Sacha Wasi Retreats - 3 Day / 2 Nights Weekend: Ayahuasca" />
                            </div>
                            <div className="article-main-content">
                                <div className='article-date'><span className="fa fa-calendar-o" aria-hidden="true"></span> October 25, 2023</div>
                                <h4 className="article-cat-title">10-Day Embodying True Nature Intensive</h4>
                                <p className="article-text">Natural supplements never mean safe kind of supplements, it is a misconception when it now to substance abuse.... Read more</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} sm={12}>
                        <div className="article-card">
                            <div className="article-image">
                                <img width="305" height="219" src={article2} alt="Sacha Wasi Retreats - 3 Day / 2 Nights Weekend: Ayahuasca" />
                            </div>
                            <div className="article-main-content">
                                <div className='article-date'><span className="fa fa-calendar-o" aria-hidden="true"></span> October 25, 2023</div>
                                <h4 className="article-cat-title">10-Day Embodying True Nature Intensive</h4>
                                <p className="article-text">Natural supplements never mean safe kind of supplements, it is a misconception when it now to substance abuse.... Read more</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="team-all text-center">
                            <a className="view-all-btn" href="">View All</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section testimonial-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h3 className="title-small">Testimonials</h3>
                        <p className="testimonial-text">Meet our experienced team members.</p>
                        <OwlCarousel className='owl-theme testimonial-carousel' {...optionsTestimonial}
                            >
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Muhammad Asif" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Muhammad Asif</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">We stayed in a glamping tent in the forest. It was amazing with comfortable beds, soft bedding and even hot water bottles. The organic food was plentiful and delicious. How do I get some of those recipes?Robin and Darcy have created a safe space where I felt truely supported to do deep work. 🙏 ❤️ I will be back!</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Henry Yusuf" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Henry Yusuf</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">It is wonderful to be held in the holy grail of community and cradled on all sides by the magic of Nature. StarRiver is a very special place to renew, restore, connect and be nourished.</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Salman Khan" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Salman Khan</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">My partner and I participated in an epic 11 day retreat with Robin and Darcy in Costa Rica. They did a great job putting on a fantastic experience in a stunning natural setting. We strongly recommend joining them if you're able to.</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Salman Khan" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Salman Khan</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">Their ability to weave such a safe and powerful container has allowed me to have such meaningful experiences and teachings that just wouldn't be possible without the consecration of this sacred space. Always feels like a privilege to sit with Robin and Darcy.</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Salman Khan" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Salman Khan</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">Their ability to weave such a safe and powerful container has allowed me to have such meaningful experiences and teachings that just wouldn't be possible without the consecration of this sacred space. Always feels like a privilege to sit with Robin and Darcy.</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="testimonial-card">
                                        <div className="testimonial-avtar">
                                            <img width="90px" height="90px" src={testimonial1} alt="Salman Khan" />
                                        </div>
                                        <div className="testimonial-overview">
                                            <h4 className="client-title">Salman Khan</h4>
                                            <p className="client-email"><a href='mailto:asif.muhammad@cmail.co' >asif.muhammad@cmail.co</a></p>
                                            <p className="client-feedback">Their ability to weave such a safe and powerful container has allowed me to have such meaningful experiences and teachings that just wouldn't be possible without the consecration of this sacred space. Always feels like a privilege to sit with Robin and Darcy.</p>
                                            <img width="40px" height="35px" className="quote-icon" src={quoteIcon} alt="quote-icon" />
                                        </div>
                                    </div>
                                </div>
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>
        </section>
        
    </div>
    );
}
export default Home;
