import React, { useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import medicine1 from '../../assets/images/medicine1.webp';
import mediatab1 from '../../assets/images/mediatab1.webp';
import './booking-plan.css';
import { useFormik } from 'formik';
import { BookingPlanSchema } from '../../schema';
import Swal from 'sweetalert2'
import { useNavigate  } from "react-router-dom";
function BookingPlan (){
    const navigate = useNavigate();
 
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthDate: '',
        dietaryPrefrence: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        donation: ''
    }
    const {values, errors, actions, touched, handleBlur, handleChange, handleSubmit} = useFormik({
     
        initialValues,
        validationSchema:BookingPlanSchema,
        onSubmit: (values,action) => {
            //  dispatch(submitBookingDetails(values)) 
            axios.post('https://heartofthejungle-api.techessentia.com/v1/notification/email', {
                values
          })
          .then(function (response) {
            console.log(response,'rep');
           
            Swal.fire({
                confirmButtonColor: '#344A1B',
                text:'Your request has been submitted , admin will contact you shortly.',
                confirmButtonText:'Go to Home Page '
              }).then((result) => {
                // window.location = "/";
                setTimeout(() => {
                    return navigate("/");
                }, 1000);
               
              }) 
          })
          .catch(function (error) {
            console.log(error);
          });
             action.resetForm();
        }
      
    })
 
    function validateEmail(value) {
        let error;
        if (!value) {
          error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          error = 'Invalid email address';
        }
        return error;
      }

    return (
        <div className="wrapper-booking-plan">
            <section className="booking-plan-section">
                <Container>
                    <form  onSubmit={handleSubmit}>
                        <Row>
                            <Col md={7} xl={9}>
                                <div className="back-wrap">
                                    <Button><span className="fa fa-chevron-left" aria-hidden="true"></span> Back</Button>
                                    <h3 className="booking-page-title">Book Your Program</h3>
                                </div>
                                <div className="program-steps-card">
                                    <div className="form-wrap">
                                        <h4 className="step-title">
                                            <span className="step-number">1</span>
                                            <span className="step-name">Checkout</span>
                                        </h4>
                                       
                                        <Row>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">First Name</Form.Label>
                                                    <Form.Control type="text" name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} placeholder="Type here..." />
                                               {errors.firstName && touched.firstName? ( <label className="error">{errors.firstName}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Last Name</Form.Label>
                                                    <Form.Control type="text" name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.lastName && touched.lastName? ( <label className="error">{errors.lastName}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Email ID</Form.Label>
                                                    <Form.Control type="email" name="email" value={values.email} validate={validateEmail()} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.email && touched.email? ( <label className="error">{errors.email}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Phone number</Form.Label>
                                                    <Form.Control type="tel" name="phoneNumber" value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.phoneNumber && touched.phoneNumber? ( <label className="error">{errors.phoneNumber}</label>) : null}
                                                </Form.Group>
                                            </Col>

                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Birth Date</Form.Label>
                                                    <Form.Control type="text" name="birthDate" value={values.birthDate} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.birthDate && touched.birthDate? ( <label className="error">{errors.birthDate}</label>) : null}
                                                </Form.Group>
                                            </Col>

                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Dietary Prefrence</Form.Label>
                                                    <Form.Control type="text" name="dietaryPrefrence" value={values.dietaryPrefrence} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.dietaryPrefrence && touched.dietaryPrefrence? ( <label className="error">{errors.dietaryPrefrence}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Emergency Contact’s Name</Form.Label>
                                                    <Form.Control type="text" name="emergencyContactName" value={values.emergencyContactName} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.emergencyContactName && touched.emergencyContactName? ( <label className="error">{errors.emergencyContactName}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} xl={6}>
                                                <Form.Group className="form-group" controlId="ControlInput1">
                                                    <Form.Label id="ControlInput1">Emergency Contact’s Phone number</Form.Label>
                                                    <Form.Control type="tel" name="emergencyContactPhone" value={values.emergencyContactPhone} onChange={handleChange} onBlur={handleBlur}  placeholder="Type here..." />
                                                    {errors.emergencyContactPhone && touched.emergencyContactPhone? ( <label className="error">{errors.emergencyContactPhone}</label>) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                         
                                    </div>
                                    <div className="form-wrap">
                                        <h4 className="step-title">
                                            <span className="step-number">2</span>
                                            <span className="step-name">Program Pricing</span>
                                        </h4>
                                        <Row>
                                            <Col md={12}>
                                                <div className="pricing-wrap">
                                                    <img width="100px" height="72px" src={mediatab1} alt="Program Pricing" />
                                                    <div className="pricing-content">
                                                        <div className="pricing-text">
                                                            <h5 className="pricing-title">10-Day Embodying True Nature Intensive - Ayahuasca Retreat</h5>
                                                            <div className="post-info-wrap d-flex">
                                                                <p className="booking-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                                                <p className="booking-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-label">
                                                            <strong>$2,280.00</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <div className="form-wrap mt-md-4 mt-3">
                                <h4 className="step-title">
                                    <span className="step-number">3</span>
                                    <span className="step-name">Room Pricing</span>
                                </h4>
                                <Row>
                                    <Col md={12}>
                                        <div className="pricing-wrap">
                                            <img width="100px" height="72px" src={mediatab1} alt="Room Pricing" />
                                            <div className="pricing-content">
                                                <div className="pricing-text">
                                                    <h5 className="pricing-title">Shared Room with Bathroom</h5>
                                                </div>
                                                <div className="pricing-label">
                                                    <strong>$1,720.00</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
                                    <div className="form-wrap mt-md-4 mt-3">
                                        <h4 className="step-title">
                                            <span className="step-number">5</span>
                                            <span className="step-name">Donation</span>
                                        </h4>
                                        <Row>
                                            <Col md={12}>
                                                <div className="pricing-wrap bg-grey">
                                                    <Row className="align-items-center">
                                                        <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                                                            <Form.Group className="form-group mb-3 mb-sm-0 donation-group" controlId="donation">
                                                                <Form.Label id="donation">$</Form.Label>
                                                                <Form.Control type="text" name="donation" value={values.donation} onChange={handleChange} onBlur={handleBlur}  placeholder="" />
                                                                {errors.donation && touched.donation? ( <label className="error">{errors.donation}</label>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={7} lg={8} xl={9}>
                                                            <div className="donation-content">
                                                                <h5 className="donation-title">Thank You for your Generosity!</h5>
                                                                <p>Giving is a clear act of conviction - supporting refuge, compassion, clarity and kindness. Dana or generosity is one of the heart qualities that the Buddha tells us leads to freedom. For many of us, our time on this beautiful land illuminates opportunities to practice dana in our daily lives.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="form-wrap mt-md-4 mt-3">
                                        <h4 className="step-title mb-0">
                                            <span className="step-number">6</span>
                                            <span className="step-name">Payment Details</span>
                                        </h4>
                                        <Row>
                                            <Col md={12}>
                                                <div className="pricing-wrap bg-transparent mt-0">
                                                    <Row className="align-items-center w-100">
                                                        <Col xs={12} xl={6}>
                                                            <div className="pay-detail-content mb-3 mb-xl-0">
                                                                <p>Pay 20% of the program price now and Book your Slot.</p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} xl={3}>
                                                            <div className="deposit-content">
                                                                <p className="p-3 d-flex flex-column mb-0">
                                                                    <strong>20% Deposit</strong>
                                                                    <span>$500.00</span>
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} xl={3}>
                                                            <div className="due-content">
                                                                <p className="p-3 d-flex flex-column mb-0">
                                                                    <strong>Due after Paying</strong>
                                                                    <span>$3500.00</span>
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="payment-footer">
                                        <Row className="align-items-center w-100">
                                            <Col md={12} lg={12} xl={6}>
                                                <div className="terms-text">
                                                    <p>I accept the terms and Conditions, attest that all information is accurate and accept full legal & personal liability for joining this program. Protecting the temple from all legal liabilities.</p>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12} xl={6}>
                                                <div className="action-btns mt-3 mt-xl-0 d-flex justify-content-xl-end">
                                                    <Button>Save for later</Button>
                                                    <Button type="submit">Book Now</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col md={5} xl={3}>
                                <div className="post-card">
                                    <div className="post-banner">
                                        <img width="285px" height="200px" src={medicine1} alt='10-Day Embodying True Nature Intensive' />
                                    </div>
                                    <div className="post-wrap">
                                        <h4 className="post-name">10-Day Embodying True Nature Intensive</h4>
                                        <p className="post-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                        <p className="post-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                        <p className="post-price">1 Person <strong>$1,230</strong></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>
        </div>
    );
}
export default BookingPlan;
