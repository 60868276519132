import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './footer.css';
import footerLogo from '../../assets/images/logo_white.webp';
function Footer() {
  return (
    <footer className='footer main-footer'>
      <Container>
        <Row>
            <Col sm={12} lg={4} xl={5} >
                <div className="footer-info">
                    <img src={footerLogo} width="150px" height="43px" alt="Footer Logo" />
                     <p>Natural supplements never mean safe supplements, it is a misconception when it comes to substance abuse. Ayahuaca is a psychoactive substance which is comprised of a few different things including banisteriopsis caapi vine.</p>
                     <ul className="social-links">
                        <li><a href="#"><span className="fa fa-whatsapp" aria-hidden="true"></span></a></li>
                        <li><a href="#"><span className="fa fa-instagram" aria-hidden="true"></span></a></li>
                        <li><a href="#"><span className="fa fa-twitter-square" aria-hidden="true"></span></a></li>
                        <li><a href="#"><span className="fa fa-facebook" aria-hidden="true"></span></a></li>
                        <li><a href="#"><span className="fa fa-youtube-play" aria-hidden="true"></span></a></li>
                     </ul>
                </div>
            </Col>
            <Col sm={12} lg={4} xl={5}>
                <div className="footer-info site-info">
                    <h3 className="widget-title">Why Heart of the Jungle?</h3>
                    <p>When someone is under the influence of Ayahuaca, the results can vary from one person to the next, because every person reacts differently to different substances. For example, some people can use ayahuasca and just find that it’s a little stimulating, while other people report having extreme visions.</p>
                </div>
            </Col>
            <Col sm={12} lg={4} xl={2}>
                <div className="footer-info">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul className="footer-links">
                      <li><a href="#">Home</a></li>
                      <li><a href="#">About</a></li>
                      <li><a href="#">Retreats</a></li>
                      <li><a href="#">Help</a></li>
                      <li><a href="#">Reviews</a></li>
                    </ul>
                </div>
            </Col>
        </Row>
      </Container>
      <div className="copyright-wrapper">
          <div className="copyright-content">
            <Container>
              <Row>
              <Col sm={12} md={6}>
                <div className="copyright-text">
                  <p>©2023<a href="#">heartofthejungle.com</a>All rights reserved</p>
                </div>
              </Col>
              <Col sm={12} md={6}>
                <ul className="copyright-links">
                  <li><a href="#">Privacy policy</a></li>
                  <li><a href="#">Terms of use</a></li>
                  <li><a href="#">Support</a></li>
                </ul>
              </Col>
              </Row>
            </Container>
          </div>
      </div>
    </footer>
  );
}

export default Footer;