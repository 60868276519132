import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {NavLink, useLocation } from 'react-router-dom';
import './header.css';
import logoMain from '../../assets/images/logo.webp';
import notificationIcon from '../../assets/images/notify-icon.svg';
import userIcon from '../../assets/images/user-icon.png';
import { useLayoutEffect, useState } from 'react';

function Header() {
const [isExpand,setIsExpand] = useState(false)

const setNavExpanded =()=>{
  setIsExpand(!isExpand)
}
const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    setIsExpand(false)
  }, [location.pathname]);
  return (
      <header className='header main-header'>
      <Navbar fixed="top" expand="xl" onToggle={setNavExpanded}
                expanded={isExpand}>
        <Container>
          <Navbar.Brand href="/">
            <img width="140px" height="40px" alt="website logo" src={logoMain}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse expand={isExpand} id="basic-navbar-nav">
            <Nav className="ms-auto mx-xl-auto">
              <NavLink className="nav-link" to="team">Our Team</NavLink>
              <NavLink className="nav-link" to="madre-ayahuasca">Ayahuasca & <br/> Medicine Retreats</NavLink>
              <NavLink className="nav-link" to="master-plants-dietas">Master Plants <br/> Dietas</NavLink>
              <NavLink className="nav-link" to="programs">All Retreats</NavLink>
              {/* <NavLink className="nav-link" to="centers">Other Retreats</NavLink> */}
              <NavLink className="nav-link" to="centers">Centers</NavLink>
              <NavLink className="nav-link" to="calender">Calender</NavLink>
              <a className="nav-link" href="#articles">Media</a>
              {/* <NavLink className="notify-icon nav-link" to="notification">
                <img width="30px" height="30px" src={notificationIcon} alt="Notification icon" />
              </NavLink> */}
              <NavLink className="user-icon nav-link" to="login">
              <img width="40px" height="40px" src={userIcon} alt="user icon" />
              </NavLink>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    
  );
}

export default Header;