import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import teamIcon from '../../assets/images/team-icon.webp';
import teamIcon2 from '../../assets/images/team-icon2.webp';
import teamIcon3 from '../../assets/images/team-icon3.webp';
import './teams.css';
function Teams() {
    return (
    <div className="wrapper-teams">
        <section className="teams-banner-section section">
            <Container>
                <Row>
                    <Col><h3 className="teams-heading text-center">Meet our experienced team members.</h3></Col>
                </Row>
            </Container>
        </section>
        <section className="section team-section">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='team-content'>
                                <h3 className="title-small">Our Team</h3>
                                <p>Meet our experienced team members.</p>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <Link to="team-detail" className="team-card mt-5">
                                <div className="team-avtar">
                                    <img width="96px" height="96px" src={teamIcon} alt='Creator Andrew' />
                                </div>
                                <div className="team-wrap">
                                    <h5 className="team-role">CREATOR · Helper</h5>
                                    <h4 className="team-member">Andrew Fletcher</h4>
                                    <p className="team-info">Aya Madre is the premier sacred plant medicine retreat to receive your healing. Ayahuasca and Shipibo plant medicine retreats...<Link to="team-detail">Read more</Link></p>
                                </div>
                            </Link>
                        </Col>
                        <Col sm={12} md={4}>
                            <Link to="team-detail" className="team-card mt-5">
                                <div className="team-avtar">
                                    <img width="96px" height="96px" src={teamIcon2} alt='Creator Andrew' />
                                </div>
                                <div className="team-wrap">
                                    <h5 className="team-role">CREATOR · Helper</h5>
                                    <h4 className="team-member">John Doe</h4>
                                    <p className="team-info">Aya Madre is the premier sacred plant medicine retreat to receive your healing. Ayahuasca and Shipibo plant medicine retreats...<Link to="team-detail">Read more</Link></p>
                                </div>
                            </Link>
                        </Col>
                        <Col sm={12} md={4}>
                            <Link to="team-detail" className="team-card mt-5">
                                <div className="team-avtar">
                                    <img width="96px" height="96px" src={teamIcon3} alt='Creator Andrew' />
                                </div>
                                <div className="team-wrap">
                                    <h5 className="team-role">CREATOR · Helper</h5>
                                    <h4 className="team-member">David Rutherford</h4>
                                    <p className="team-info">Aya Madre is the premier sacred plant medicine retreat to receive your healing. Ayahuasca and Shipibo plant medicine retreats...<Link to="team-detail">Read more</Link></p>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
    </div>
    );
}
export default Teams;
