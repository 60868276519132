import './App.css';
import React, {useLayoutEffect} from 'react';
import { Routes, useLocation, Route} from "react-router-dom";
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import Programs from './pages/programs/programs';
import Details from './pages/details/details';
import BookingPlan from './pages/booking-plan/booking-plan';
import MadreAyahuasca from './pages/madre-ayahuasca/madre-ayahuasca';
import MasterPlantsDietas from './pages/master-plants-dietas/master-plants-dietas';
import Centers from './pages/centers/centers';
import Teams from './pages/teams/teams';
import TeamDetail from './pages/team-detail/team-detail';
import Calender from './pages/calender/calender';
function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="App">
      <div className="wrapper">
          <Header />
          <Routes>
            <Route strict path="/" element={<Home />} />
            <Route path="programs" element={<Programs />}></Route>
            <Route path="programs/details" element={<Details />}></Route>
            <Route path="programs/details/booking-plan" element={<BookingPlan />}></Route>
            <Route path="madre-ayahuasca" element={<MadreAyahuasca />}></Route>
            <Route path="master-plants-dietas" element={<MasterPlantsDietas />}></Route>
            <Route path="centers" element={<Centers />}></Route>
            <Route path="team" element={<Teams />}></Route>
            <Route path="team/team-detail" element={<TeamDetail />}></Route>
            <Route path="calender" element={<Calender />}></Route>
          </Routes>
          <Footer />
      </div>
    </div>
  );
}
export default App;
