import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import medicine1 from '../../assets/images/medicine1.webp';
import medicine2 from '../../assets/images/medicine2.webp';
import medicine3 from '../../assets/images/medicine3.webp';
import './programs.css';
function Programs() {
    return (
    <div className="wrapper-programs">
        <section className="section filter-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="program-filters">
                            <Row>
                                <Col lg={12} xl={7}>
                                    <div className="search-filter-listing">
                                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Search here..." />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Date</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Duration</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Price</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col lg={12} xl={5}>
                                    <div className="sort-filter-listing">
                                        <Button>Weekend</Button>
                                        <Button>Upcoming</Button>
                                        <Button>Affordable</Button>
                                        <Button>Top Rated</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section filter-panes">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="tabs-maps">
                            <ul className="nav nav-pills" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="pill" href="#allPrograms">All Programs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="pill" href="#madreAyahuasca">Madre Ayahuasca</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="pill" href="#masterPlantsDietas">Master Plants Dietas</a>
                                </li>
                            </ul>
                            <div class="switch-maps d-none">
                                <span className="map-text">Map View</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div id="allPrograms" className="tab-pane active">
                                <div className="media-content-tab">
                                    <Row>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Aug 10, 2023 to Aug 24, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Oct 5, 2023 to Oct 19, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Dec 2, 2023 to Dec 16, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Aug 10, 2023 to Aug 24, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Oct 5, 2023 to Oct 19, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Dec 2, 2023 to Dec 16, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div id="madreAyahuasca" className="tab-pane fade">
                                <div className="media-content-tab">
                                    <Row>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Aug 10, 2023 to Aug 24, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Oct 5, 2023 to Oct 19, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Dec 2, 2023 to Dec 16, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <Link to="details" className="programs-card">
                                                <div className="programs-banner">
                                                    <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                                </div>
                                                <div className="programs-wrap">
                                                    <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                    <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Aug 10, 2023 to Aug 24, 2023</p>
                                                    <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                    <p className="programs-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div id="masterPlantsDietas" className="tab-pane fade">
                                <div className="media-content-tab">
                                <Row>
                                    <Col sm={6} lg={3}>
                                        <Link to="details" className="programs-card">
                                            <div className="programs-banner">
                                                <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="programs-wrap">
                                                <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                <p className="programs-price">From <strong>$1,230</strong></p>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Link to="details" className="programs-card">
                                            <div className="programs-banner">
                                                <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="programs-wrap">
                                                <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Aug 10, 2023 to Aug 24, 2023</p>
                                                <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                <p className="programs-price">From <strong>$1,230</strong></p>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Link to="details" className="programs-card">
                                            <div className="programs-banner">
                                                <img width="305px" height="215px" src={medicine3} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="programs-wrap">
                                                <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Oct 5, 2023 to Oct 19, 2023</p>
                                                <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                <p className="programs-price">From <strong>$1,230</strong></p>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Link to="details" className="programs-card">
                                            <div className="programs-banner">
                                                <img width="305px" height="215px" src={medicine2} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="programs-wrap">
                                                <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>Dec 2, 2023 to Dec 16, 2023</p>
                                                <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                <p className="programs-price">From <strong>$1,230</strong></p>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Link to="details" className="programs-card">
                                            <div className="programs-banner">
                                                <img width="305px" height="215px" src={medicine1} alt='14 DAYS MASTER PLANT DIET' />
                                            </div>
                                            <div className="programs-wrap">
                                                <h4 className="programs-name">14 DAYS MASTER PLANT DIET</h4>
                                                <p className="programs-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>July 15, 2023 to July 29, 2023</p>
                                                <p className="programs-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Amazon, Peru</p>
                                                <p className="programs-price">From <strong>$1,230</strong></p>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    );
}
export default Programs;
