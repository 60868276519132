import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import centerBanner from '../../assets/images/center-banner.webp';
import centerTop from '../../assets/images/center-1.webp';
import centerBottom from '../../assets/images/center-2.webp';
import './centers.css';
const optionsCenters = {
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: true,
    stagePadding: 60,
    loop: true,
    autoplay: false,
    items: 1,
    navText: ["<span class='fa fa-angle-left' aria-hidden='true'></span>","<span class='fa fa-angle-right' aria-hidden='true'></span>"],
    smartSpeed: 1000
};
function Centers() {
    return (
    <div className="wrapper-center">
        <section className="section center-page-section">
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <OwlCarousel className='owl-theme center-page-carousel' {...optionsCenters}>
                            <div className='item'>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                        <div className="center-image">
                                            <img width="1279px" height="390px" src={centerBanner} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                        </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='item'>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                        <div className="center-image">
                                            <img  width="1279px" height="390px" src={centerBanner} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                        </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='item'>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                        <div className="center-image">
                                            <img  width="1279px" height="390px" src={centerBanner} alt="Harem Pants w/Pockets Shipibo Icaro Pattern" />
                                        </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section amazon-section">
            <Container>
                <Row>
                    <Col className="amazon-title-section" md={12}>
                        <h3 className="amazon-title">Amazon, Peru</h3>
                        <p>This centre is an Eco Lodge located in the banks of the Ucayali River near Iquitos on the heart of the Peruvian Amazon. We host weekly holistic healing retreats, where visitors from all over the world come an experience traditional Amazonian Plant Medicine.</p>
                    </Col>
                    <Col md={8} lg={8}>
                        <h4 className="amazon-subtitle">Who are the Shipibos?</h4>
                        <div className="amazon-text">
                            <p>Originally two groups - the Shipibo (apemen) and the Konibo (fishmen), eventually became one distinct tribe through intermarriage, and communal ritual.</p>
                            <p>Neither the Shipibo nor the Konibo (sometimes spelt Conibo) were conquered by the Inca Empire. They successfully resisted attempts at colonization by Franciscan priests, who began appearing in the Amazon rainforest in the 17th Century.</p>
                            <p>The Shipibo-Konibo have traditionally lived along the Ucayali River, a large tributary of the Amazon; and the Franciscans eventually established a settlement in Shipibo territory, near the present-day port city of Pucallpa. They have worked with ayahuasca for hundreds of years, and possibly longer still.</p>
                            <p>These peoples are some of the main guardians of this medicinal healing art. Most of their works of art, fabrics, beadings, potteries and even their house adornments are decorated with the patterns of ayahuasca visions.</p>
                        </div>
                    </Col>
                    <Col md={4} lg={4}>
                        <div className="amazon-images text-md-end text-center">
                            <img width="209" height="248" className="main-iamge" src={centerTop} alt="" />
                            <img width="209" height="248" className="bottom-iamge" src={centerBottom} alt="" />
                        </div>
                    </Col>
                    <Col md={4} lg={4}>
                        <div className="amazon-images text-md-start text-center mt-md-5">
                            <img width="209" height="248" className="main-iamge" src={centerTop} alt="" />
                            <img width="209" height="248" className="bottom-iamge" src={centerBottom} alt="" />
                        </div>
                    </Col>
                    <Col md={8} lg={8}>
                        <h4 className="amazon-subtitle mt-3 mt-md-5">Where it will take place?</h4>
                        <div className="amazon-text">
                            <p>Paoyhan is a small village situated in Contamana County, along the Ucayali River. It is about a four hour trip by motor boat from Pucallpa, Peru. It is a small and humble village with only one main street. The Center where the diet takes place is situated in the middle of the village. Upon arrival, you will be received at the family house which is close to a school, and the center of the community. From the kitchen or from your tambo (small hut) you can see the kids playing soccer, and people cultivating the land.</p>
                            <p>An octagonal ceremonial space known as a maloca was built on the land years ago, and this is where all of the ceremonies take place. There are many small tambos around the central part of the land, and each participant will get their own personal one for the duration of the dieta. Here, you can relax, lie in your hammock, and contemplate the river and tranquility of the beautiful Amazonian ecosystem.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    );
}
export default Centers;
