import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import teamDetail from '../../assets/images/team-detail-image.webp';
import './team-detail.css';
function TeamDetail() {
    return (
    <div className="wrapper-team-detail">
        <section className="section team-detail-section">
                <Container>
                    <Row>
                        <Col sm={12} md={4} lg={5}>
                            <div className="team-detail-figure">
                                <div className="team-detail-avtar">
                                    <img width="502" height="502" src={teamDetail} alt='Creator Andrew' />
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={7}>
                            <div className="team-detail-content">
                                <div className="team-detail-text">
                                    <div className="maestro-wrapper">
                                        <p>Your Maestro</p>
                                        <h3>Papa Gilberto Mahua</h3>
                                    </div>
                                    <div className="maestro-text-wrap">
                                        <p>"Papa" Gilberto, as his students call him, is one of the last curanderos (healers) that received an extended training in traditional curanderismo. He started dieting at the age of twelve years old, along with four of his brothers and cousins. His first dieta was eighteen months long, in total isolation in the jungle. They would only join the group for the Ayahuasca ceremonies. Then, through the years, he repeated dietas with all of the plants he masters and serves now. </p>
                                        <p>Papa Gilberto Mauha is from a lineage of powerful Maestros. His father, grandfather and great grandfather were also healers and the knowledge was passed down from generation to generation. It is said that his ancestors were at the most advanced level of curandero one can attain. A healer who has achieved this status is called a Meraya. They were known to have the power of shapeshifting, visiting other planets, performing energetic and physical surgeries, and more. In the modern day context with the influences of our western culture, it is more difficult for modern day curanderos to attain this level of mastery.</p>
                                        <p> Gilberto Mahua is a very humble man. Although he is a knowledgeable teacher, he doesn't speak a lot, and only shares with his students when necessary. He guides and encourages his students to learn by themselves, and to discover their own inner healer. He is dedicated to his medicine work and his community.</p>
                                        <p>He is an honest, reliable and responsible teacher that works for the best of all his students, allowing them to make their own choices while he keeps an eye on them. His singing is powerful and very unique, and after learning with his father and grandfather, he developed his own personal way of singing the icaros with complex voice melodies, and rhythmic patterns.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    </div>
    );
}
export default TeamDetail;
