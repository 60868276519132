import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import medicine1 from '../../assets/images/medicine1.webp';
import medicine2 from '../../assets/images/medicine2.webp';
import medicine3 from '../../assets/images/medicine3.webp';
import Table from 'react-bootstrap/Table';
import './master-plants-dietas.css';
import masterImage from '../../assets/images/master-image.webp';
function MasterPlantsDietas() {
    return (
    <div className="wrapper-product">
        <section className="product-banner-section master-banner section">
            <Container>
                <Row>
                    <Col><h3 className="product-heading text-center">Master Plants Dietas</h3></Col>
                </Row>
            </Container>
        </section>
        <section className="section about-product-section">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="about-product-content">
                            <h4 className="about-product-title">Ayahuasca</h4>
                            <p>Ayahuasca is a potent plant medicine that is known to instill life-changing insights in those who drink it.</p>
                            <p>The brew has been revered by Amazonian indigenous tribes, and similar to how native Americans use Peyote (San Pedro), Peruvian Ayahuasca shamans have been using the brew to reach higher states of awareness and consciousness. Over time, the word has spread, and with thousands of Ayahuasca tourists looking to embark on their own transformative healing journey, multiple Ayahuasca retreats have been popping up from Costa Rica to Peru*....  Read More</p>
                            <h4 className="about-product-title mt-3">Medicines Retreats</h4>
                            <p>The brew has been revered by Amazonian indigenous tribes, and similar to how native Americans use Peyote (San Pedro), Peruvian Ayahuasca shamans have been using the brew to reach higher states of awareness and consciousness. Over time, the word has spread, and with thousands of Ayahuasca tourists looking to embark on their own transformative healing journey, multiple Ayahuasca retreats have been popping up from Costa Rica to Peru*....  Read More</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="about-product-media mt-3 mt-md-0 text-center text-md-right">
                            <img width="465" height="263" src={masterImage} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section product-layout-section">
            <Container>
                <Tab.Container id="layout-views" defaultActiveKey="list">
                    <Row>
                        <Col md={12}>
                            <h4 className="about-product-title">Would you like to begin your own life-changing Ayahuasca healing?</h4>
                            <div className="filter-products d-md-flex justify-content-between">
                                <div className="search-filter-listing">
                                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Search here..." />
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Select>
                                            <option>Date</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Select>
                                            <option>Duration</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Select>
                                            <option>Price</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="layout-toggle my-3 mt-md-0">
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="grid">
                                            Grid View <span class="fa fa-th-large" aria-hidden="true"></span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="list">
                                            List View<span class="fa fa-list-ul" aria-hidden="true"></span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="layout-view">
                            <Tab.Content>
                                <Tab.Pane eventKey="grid">
                                    <Row className="product-list-row">
                                        <Col sm={6} lg={3}>
                                            <div className="product-list-card mt-3">
                                                <div className="product-list-banner">
                                                    <img width="305" height="215" src={medicine1} alt='10-Day Embodying True Nature Intensive' />
                                                </div>
                                                <div className="product-list-wrap">
                                                    <h4 className="product-list-name">10-Day Embodying True Nature Intensive</h4>
                                                    <p className="product-list-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                                    <p className="product-list-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <p className="product-list-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <div className="product-list-card mt-3">
                                                <div className="product-list-banner">
                                                    <img width="305" height="215" src={medicine2} alt='10-Day Embodying True Nature Intensive' />
                                                </div>
                                                <div className="product-list-wrap">
                                                    <h4 className="product-list-name">10-Day Embodying True Nature Intensive</h4>
                                                    <p className="product-list-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                                    <p className="product-list-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <p className="product-list-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <div className="product-list-card mt-3">
                                                <div className="product-list-banner">
                                                    <img width="305" height="215" src={medicine3} alt='10-Day Embodying True Nature Intensive' />
                                                </div>
                                                <div className="product-list-wrap">
                                                    <h4 className="product-list-name">10-Day Embodying True Nature Intensive</h4>
                                                    <p className="product-list-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                                    <p className="product-list-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <p className="product-list-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <div className="product-list-card mt-3">
                                                <div className="product-list-banner">
                                                    <img width="305" height="215" src={medicine2} alt='10-Day Embodying True Nature Intensive' />
                                                </div>
                                                <div className="product-list-wrap">
                                                    <h4 className="product-list-name">10-Day Embodying True Nature Intensive</h4>
                                                    <p className="product-list-date"><span className="fa fa-calendar-o" aria-hidden="true"></span>October 21 - 31, 2023</p>
                                                    <p className="product-list-location"><span className="fa fa-map-marker" aria-hidden="true"></span>Paititi Institute, Peru</p>
                                                    <p className="product-list-price">From <strong>$1,230</strong></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="team-all text-center">
                                                <a className="view-all-btn" href="">View Programs</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane className="product-list-view" eventKey="list">
                                    <Row>
                                        <Col>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                   <strong>June 2023</strong> · <span>8 Programs</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>program</th>
                                                            <th>price</th>
                                                            <th>status</th>
                                                            <th>actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><strong>July 2023 · 24 Programs</strong> · <span>8 Programs</span></Accordion.Header>
                                                <Accordion.Body>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>program</th>
                                                            <th>price</th>
                                                            <th>status</th>
                                                            <th>actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header><strong>Augest 2023 · </strong><span>11 Programs</span></Accordion.Header>
                                                <Accordion.Body>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>program</th>
                                                            <th>price</th>
                                                            <th>status</th>
                                                            <th>actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>October 21 - 31, 2023</td>
                                                                <td>10-Day Embodying True Nature Intensive</td>
                                                                <td>From $1,230</td>
                                                                <td>A few spaces left</td>
                                                                <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    </div>
    );
}
export default MasterPlantsDietas;
