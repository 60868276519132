import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import './calender.css';
function Calender() {
    return (
    <div className="wrapper-calender">
        <section className="section filter-section">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="calender-filters">
                            <Row>
                                <Col lg={12} xl={7}>
                                    <div className="search-filter-listing">
                                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Search here..." />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Date</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Duration</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Select>
                                                <option>Price</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col lg={12} xl={5}>
                                    <div className="sort-filter-listing">
                                        <Button>Weekend</Button>
                                        <Button>Upcoming</Button>
                                        <Button>Affordable</Button>
                                        <Button>Top Rated</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="section product-layout-section">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="layout-view">
                            <div className="product-list-view">
                                <Row>
                                    <Col className="calender-table px-0">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>program</th>
                                                <th>price</th>
                                                <th>status</th>
                                                <th>actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td>October 21 - 31, 2023</td>
                                                    <td>10-Day Embodying True Nature Intensive</td>
                                                    <td>From $1,230</td>
                                                    <td>A few spaces left</td>
                                                    <td><a className="view-all-btn" href="#">REGISTER NOW</a></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    );
}
export default Calender;
