import { createSlice } from '@reduxjs/toolkit'
 
 

const initialState = {
  bookingDetails: [],
}

export const bookingPlanSlice = createSlice({
  name: 'BookingPlan',
  initialState,
  reducers: {
    // submitBookingDetails: (state,action) => {
    //     const data = action.payload
    //     axios.post('https://heartofthejungle-api.techessentia.com/v1/notification/email', {
    //         data
    //       })
    //       .then(function (response) {
    //         console.log(response,'rep');
           
    //         Swal.fire({
    //             confirmButtonColor: '#344A1B',
    //             text:'Your request has been submitted , admin will contact you shortly.',
    //             confirmButtonText:'Go to Home Page '
    //           }).then((result) => {
    //             // window.location = "/";
    //             Redirect();
    //           }) 
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // //   state.bookingDetails.push(action.payload)
    // },
   
  },
})
// https://heartofthejungle-api.techessentia.com/v1/notification/email
 
export const { submitBookingDetails } = bookingPlanSlice.actions

export default bookingPlanSlice.reducer